import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { checkTokenExpiry } from './modules/shared/guards/check-token-expiry.guard';
import { unsubscribedUserGuard } from './modules/shared/guards/unsubscribed-user.guard';

export const routes: Routes = [
  {
    path: '',
    canActivate: [checkTokenExpiry, unsubscribedUserGuard],
    loadChildren: () => import('./modules/main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'pages',
    canActivate: [checkTokenExpiry, unsubscribedUserGuard],
    loadChildren: () => import('./modules/pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'public',
    loadChildren: () => import('./modules/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: '**', redirectTo: '/public/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
