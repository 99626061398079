<div class="cinepoint-webapp">
  <p-toast
    position="top-center"
    key="tc"
    [life]="5000"
    styleClass="cine-toast"
  ></p-toast>
  <router-outlet class="full-h-container"></router-outlet>
  @if (isLoading) {
    <app-loader-animation></app-loader-animation>
  }
</div>
