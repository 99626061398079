import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // Import BrowserAnimationsModule
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { tokenInterceptor } from './interceptors/token-interceptor.interceptor';
import { SharedModule } from './modules/shared/shared.module';
import { BaseCardMovieComponent } from './shared/components/base-card-movie/base-card-movie.component';

@NgModule({
  declarations: [AppComponent, BaseCardMovieComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    AppRoutingModule,
    SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi:true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi:true
    },
    provideHttpClient(withInterceptors([
      tokenInterceptor
    ])),
    provideRouter(routes, withComponentInputBinding())
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
