import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

export const checkTokenExpiry: CanActivateFn = async (route, state) => {
  const authService = inject(AuthService);
  const user = authService.getCurrentUser();
  const token = authService.getCurrentToken();

  if ( user && !token ) {
    authService.sessionExpired();
  } else if (token) {
    try {
      await firstValueFrom( authService.getUserProfile() );
    } catch (err) {
      return false;
    }
  }

  return true;
};
