import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { SubscriptionService } from '../services/subscription/subscription.service';
import { isUserSubscribed } from '../util/helper';

export const unsubscribedUserGuard: CanActivateFn = async (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const user = authService.getCurrentUser();

  if ( user && !isUserSubscribed(user) ) {
    const subsService = inject(SubscriptionService);
    const userNewInfo = await firstValueFrom( subsService.getCurrentSubscription() );
    if (isUserSubscribed(userNewInfo)) {
      user.plan = userNewInfo.plan;
      user.subscription = userNewInfo.subscription;
      authService.storeCurrentUser(user);
      return true;
    }
    router.navigate(['auth', 'select-plan']);
    return false;
  }

  return true;
};
