import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, retry, switchMap, throwError } from 'rxjs';
import { Endpoint } from '../constants/endpoint.constant';
import { SignInResponse } from '../model/signin-response.model';
import { AuthService } from '../modules/shared/services/auth/auth.service';
import { ToastService } from '../modules/shared/services/toast.service';
import { getErrorMessage } from '../modules/shared/util/helper';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toast: ToastService,
    private router: Router,
    private translate: TranslateService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (!this.isIgnoredUrl(request.url)) {

          this.makeSurePropertyNamesCorrect(err);
          // check for support of bilingual error message
          const errorMessage = err.error?.response_schema?.response_message
            ?.english
            ? getErrorMessage(err, this.translate)
            : `Error: ${err.status}`;

          // handle generic server error
          if (err.status === 401) {
            if (this.authService.getCurrentUser()) {
              this.authService.sessionExpired();
            } else {
              this.authService.notLoggedIn();
            }
          }
        }
        return throwError(() => err);
      })
    );
  }

  makeSurePropertyNamesCorrect(err: any): void {
    const responseSchema = err.error?.response_schema;
    if (!responseSchema) return;
    if (!responseSchema?.response_code && responseSchema?.code) {
      err.error.response_schema = {
        response_code: responseSchema.code,
        response_message: responseSchema.message
      }
    }
  }

  isIgnoredUrl(url: string): boolean {
    return [Endpoint.REFRESH_TOKEN].includes(url);
  }

  private renewTokenAndRetry(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.refreshToken().pipe(
      switchMap((res: SignInResponse) => {
        this.authService.storeToken(res.access_token!);
        return next.handle(request.clone()); // retry the original request
      }),
      retry(2), // retry up to 2 times
      catchError((err) => {
        this.authService.sessionExpired();
        return throwError(() => err);
      })
    );
  }
}
