import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../modules/shared/services/auth/auth.service';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.headers.has('Authorization')) return next(req);

  const authService = inject(AuthService);
  const authToken = authService.getCurrentToken();

  // Clone the request and add the authorization header
  const authReq = authToken ? req.clone({
    setHeaders: {
      Authorization: `Bearer ${authToken}`
    }
  }) : req;

  // Pass the cloned request with the updated header to the next handler
  return next(authReq);
};
