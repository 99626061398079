import { Component, ElementRef } from '@angular/core';

// language list
import { registerLocaleData } from '@angular/common';
import localeEN from '@angular/common/locales/en';
import localeID from '@angular/common/locales/id';
import { NavigationEnd, Router } from '@angular/router';
import { TranslationService } from './modules/i18n';
import { localeEn } from './modules/i18n/vocabs/en';
import { localeId } from './modules/i18n/vocabs/id';
import { CountryService } from './modules/shared/services/country/country.service';
import { LoaderAnimationService } from './modules/shared/services/loader-animation.service';
import { MovieService } from './modules/shared/services/movie/movie.service';
import { DataLayerService } from './shared/services/data-layer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'cinepoint_web';
  isLoading: boolean = true;

  constructor(
    private translationService: TranslationService,
    private countryService: CountryService,
    private movieService: MovieService,
    private elementRef: ElementRef,
    private loaderService: LoaderAnimationService,
    private dataLayerService: DataLayerService,
    private router: Router
  ) {

    const headerHeight = this.elementRef.nativeElement.querySelector('header')?.offsetHeight ?? '193';
    document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
    this.fetchCountryList();
    this.fetchGenreList();
    this.fetchRatingList();
    this.setupLanguage();
    this.loaderService.isLoading.subscribe((val)=>{
      setTimeout(() => {
        this.isLoading = val;
      }, 0);
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.dataLayerService.logPageView(event.url);
      }
    });
  }

  setupLanguage(): void {
    // register translations
    this.translationService.loadTranslations(localeEn, localeId);
    registerLocaleData(localeID, 'id');
    registerLocaleData(localeEN, 'en');
    this.translationService.setLanguage(this.translationService.getSelectedLanguage());
  }

  fetchGenreList(): void {
    this.movieService.initializeGenreListCache();
  }

  fetchRatingList(): void {
    this.movieService.initializeRatingListCache();
  }

  fetchCountryList(): void {
    this.countryService.initializeCountryListCache();
  }
}
