// Bahasa Indonesia
export const localeId = {
  lang: 'id',
  data: {
    ERROR_MSG: {
      INCORRECTOTP: 'Kode OTP salah. Silakan coba lagi.',
      PASSWORD: 'Kata sandi salah, silakan coba lagi.',
      OLDPASSWORD: 'Anda telah memasukkan kata sandi sebelumnya. Silakan buat yang baru.',
      UNREGISTEREDEMAIL: 'Emailnya tidak ada. Silakan mendaftar sebagai gantinya',
      UNREGISTEREDPHONE: 'Nomor telepon tidak ada. Silakan mendaftar sebagai gantinya',
      NOTREGISTERED: 'Email atau nomor telepon tidak ada. Silakan mendaftar sebagai gantinya',
      REQUIRED: '{{fieldName}} tidak boleh kosong.',
      MAXLENGTH: '{{fieldName}} harus tidak lebih panjang dari {{requiredLength}} karakter.',
      CONFIRMPASSWORD: 'Kata sandi tidak cocok.',
      ALREADYREGISTEREDEMAIL: 'Telah digunakan. Silakan masukkan email lain atau masuklah.',
      ALREADYREGISTEREDPHONE: 'Telah digunakan. Silakan masukkan nomor telepon lain atau masuk saja.',
      EMAIL: 'Silakan masukkan format email yang valid.',
      PHONE: 'Silakan masukkan format nomor telepon yang valid.',
      EMAILMUSTBEDIFFERENT: 'Anda tidak dapat menggunakan email saat ini sebagai email baru.',
      CHANGEEMAILEXISTS: 'Email sudah digunakan. Silakan masukkan yang lain',
      CONTENTUNAVAIL: 'The following content is not available on this app.',
    },
    TEXT: {
      WELCOME_TITLE: 'Selamat Datang di Cinepoint',
      WELCOME_BANNER: `We measure the heartbeat of Indonesian Cinema.` // Do not translate this
    },
    FULL_MONTH: {
      '0': 'Januari',
      '1': 'Februari',
      '2': 'Maret',
      '3': 'April',
      '4': 'Mei',
      '5': 'Juni',
      '6': 'Juli',
      '7': 'Agustus',
      '8': 'September',
      '9': 'Oktober',
      '10': 'November',
      '11': 'Desember'
    },
    SIGN_IN: {
      TITLE: 'Masuk ke Akun',
      DONT_HAVE_ACC:'Atau tidak punya akun?'
    },
    SIGN_UP: {
      PLACEHOLDER_FIRSTNAME: 'Masukkan nama depan Anda',
      PLACEHOLDER_LASTNAME: 'Masukkan nama belakang Anda',
      PLACEHOLDER_EMAIL: 'mail@email.com',
      PLACEHOLDER_PHONE: '812324567890',
      PLACEHOLDER_PASSWORD: 'Masukkan kata sandi Anda',
      PLACEHOLDER_CONFIRM_PASSWORD: 'Ketik ulang kata sandi Anda',
      TITLE: 'Daftar di Cinepoint',
      ALREADY_HAVE_ACC: 'Atau sudah punya akun?',
      MUST_BE_8: 'Harus minimal 8 karakter',
      ONE_NO: 'Satu angka',
      ONE_UPPER: 'Satu huruf kapital',
      ONE_SPECIAL: 'Satu karakter spesial !@#$%^&*'
    },
    ACCOUNT_SETUP: {
      TITLE: 'Kamu hampir selesai!',
      PROMPT: 'Silakan masukkan email dan kata sandi Anda untuk melanjutkan',
    },
    SELECT_PLAN: {
      VOUCHER_NOTEXIST: 'Kode voucher tidak valid. Coba gunakan kode lain.',
      VOUCHER_EXPIRED: 'Kode voucher sudah habis masa berlakunya. Coba gunakan kode lain.',
      VOUCHER_RUNOUT: 'Kuota voucher telah habis. Coba gunakan kode lain.',
      VOUCHER_USED: 'Anda telah menggunakan voucher ini sebelumnya. Coba gunakan kode lain.',
      VOUCHER_CLAIMED_FREEPLAN: 'Kode voucher valid. Anda berlangganan gratis sampai {{endDate}}.',
      VOUCHER_CLAIMED_ADDITIONAL: 'Kode voucher valid. Anda menerima tambahan {{duration}} hari berlangganan gratis.',
      TITLE: 'Buka kunci semua fitur',
      DESC: 'Dapatkan akses instan ke semua fitur yang ditawarkan Cinepoint!',
      VOUCHER_PROMPT: 'Miliki kode voucher?',
      VOUCHER_PLACEHOLDER: 'Masukkan kode voucher',
      BUTTON_CHECKOUT: 'Pembayaran sebesar {{price}},-/{{frequency}}',
      BUTTON_CHECKOUT_FREE: 'Pembayaran Gratis',
      ALL_BASIC: {
        TITLE: 'Akses ke Semua Fitur Dasar',
        DESC: 'Buka kunci akses penuh ke semua fitur penting. Nikmati penjelajahan tanpa batas, akses mudah ke informasi film tanpa batasan apa pun.'
      },
      REPORTING_ANALYTICS: {
        TITLE: 'Pelaporan dan Analisis Dasar',
        DESC: 'Tetap terinformasi tentang tren box office dan pertunjukan film dengan pelaporan dan analisis dasar.'
      },
      COMPARISON: {
        TITLE: 'Perbandingan Film',
        DESC: 'Bandingkan tarif masuk, penghasilan kotor, waktu tayang, dan peringkat hingga 3 film pilihan Anda secara berdampingan.'
      }
    },
    SELECT_PAYMENT: {
      CODE_EXPIRED: 'Kode promo sudah habis masa berlakunya. Coba gunakan kode lain.',
      CODE_NOTEXIST: 'Kode promo tidak valid. Coba gunakan kode lain.',
      CODE_RUN_OUT: 'Kuota promo telah habis. Coba gunakan kode lain.',
      CODE_USED: 'Anda telah menggunakan kode ini sebelumnya. Silakan coba gunakan kode lain.',
      CODE_VALID: 'Kode promo valid',
      TITLE: 'Pilih metode pembayaran',
      DESC: 'Pilih opsi pembayaran pilihan Anda',
      PROMO_PROMPT: 'Punya kode promosi?',
      PROMO_CODE_PLACEHOLDER: 'Masukkan kode promo',
      BASIC_FEATS: 'Akses ke semua fitur dasar',
      UPCOMING_MOVIES: 'Dapatkan info terbaru tentang film mendatang',
      BASIC_REPORTING: 'Pelaporan dan analisis dasar',
      BASIC_SUPPORT: 'Dukungan obrolan dan email dasar',
      MOVIE_COMPARISON: 'Perbandingan film',
      ANOTHER_FEAT: 'Fitur lainnya',
      CHARGE_DESCRIPTION: 'Anda akan dikenakan biaya Rp{{price}},- /{{frequency}} hingga anda memutuskan untuk membatalkan langganan.',
      WHAT_YOU_GET: 'Apa yang akan Anda dapatkan dalam paket ini :',

    },
    VERIF_EXPIRED: {
      TITLE: 'Tautan verifikasi telah kedaluwarsa!',
      DESC: 'Sepertinya tautan verifikasi telah kedaluwarsa. Namun yakinlah, kami dapat mengirimkan tautannya lagi.'
    },
    ACC_VERIFIED: {
      TITLE: 'Akun Anda telah diverifikasi!',
      DESC: 'Akun Anda telah berhasil diverifikasi. Klik "Berikutnya" untuk melanjutkan.'
    },
    NEW_EMAIL_VERIFIED: {
      TITLE: 'Email baru Anda telah diverifikasi!',
      DESC: 'Email baru Anda telah berhasil diverifikasi. Silakan klik “Masuk” untuk melanjutkan.'
    },
    NOT_LOGGED_IN: {
      TITLE: 'Anda belum masuk!',
      DESC: 'Silakan klik tombol Masuk pada halaman Beranda dan masukkan nama pengguna dan kata sandi Anda.'
    },
    SESSION_EXPIRED: {
      TITLE: 'Sesi Anda telah berakhir!',
      DESC: 'Anda dapat mencoba Masuk ke akun Anda lagi, atau buka Beranda kami.'
    },
    VERIFY_OTP: {
      TITLE: 'Verifikasi Nomor Telepon',
      HAVE_SENT: 'Kami telah mengirimkan kode OTP ke',
      PROMPT: 'Silakan masukkan kode di bawah ini.',
      DID_NOT_RECEIVE: 'Tidak menerima kodenya?'
    },
    VERIFY_ACCOUNT: {
      TITLE: 'Verifikasi akun anda',
      VERIFY_PROMPT: 'Anda perlu memverifikasi email Anda untuk menyelesaikan pendaftaran',
      EMAIL_SENT_START: 'Email telah dikirim ke ',
      EMAIL_SENT_END: ' dengan tautan untuk memverifikasi akun anda.',
      EMAIL_SENT_VERIFY: 'Harap verifikasi akun Anda terlebih dahulu sebelum masuk.',
      RESEND_VERIFICATION_LINK: 'Kirim ulang tautan verifikasi'
    },
    ACTIVATE_ACCOUNT: {
      TITLE: 'Aktivasi akun anda',
      VERIFY_PROMPT: 'Anda perlu memverifikasi email Anda untuk menyelesaikan pengaturan',
      EMAIL_SENT_START: 'Email telah dikirim ke',
      EMAIL_SENT_END: ​​'dengan link untuk mengaktifkan akun Anda.',
      EMAIL_SENT_VERIFY: 'Harap aktifkan akun Anda terlebih dahulu sebelum masuk.',
      RESEND_VERIFICATION_LINK: 'Kirim ulang tautan aktivasi'
    },
    PAYMENT_SUCCESS: {
      TITLE: 'Pembayaran Berhasil!',
      DESC: 'Hore, Anda berlangganan! Sekarang Anda dapat menikmati fitur luar biasa kami!'
    },
    PAYMENT_UNFINISH: {
      TITLE: 'Pembayaran Tidak Selesai!',
      DESC: 'Anda belum menyelesaikan pembayaran. Silakan pilih paket dan lanjutkan ke pembayaran.'
    },
    PAYMENT_ERROR: {
      TITLE: 'Kesalahan Memproses Pembayaran!',
      DESC: 'Terjadi kesalahan saat memproses pembayaran. Silakan tanyakan kepada penyedia layanan pembayaran Anda apakah transaksi telah diproses atau belum. Anda mungkin perlu mengulangi pemilihan paket dan pembayaran.'
    },
    EMAIL_SENT: {
      TITLE: 'Email Terkirim!',
    },
    PASSWORD_FORM: {
      GREETING: 'Hai, {{name}}!',
      PROMPT: 'Silakan masukkan kata sandi Anda untuk melanjutkan proses Masuk.',
      REMEMBER_PASSWORD: 'Ingat selama 30 hari'
    },
    FORGOT_PASSWORD: {
      TITLE: 'Lupa Kata Sandi',
      PROMPT: 'Silakan masukkan alamat email Anda yang terdaftar untuk mengubah kata sandi Anda.',
      EMAIL_SENT_END: ​​'dengan tautan untuk mengubah kata sandi Anda.',
    },
    CREATE_PASSWORD: {
      TITLE: 'Buat Kata Sandi Baru',
      DESC: 'Silakan masukkan kata sandi baru Anda.'
    },
    PASSWORD_CHANGED: {
      TITLE: 'Kata Sandi Berubah!',
      DESC: 'Kata sandi Anda telah berhasil diubah. Sekarang Anda dapat Masuk menggunakan kata sandi baru Anda.'
    },
    FOOTER: {
      SHORT_DESC: 'We measure the heartbeat of Indonesian Cinema.', // Do not translate this
      GET_APP: 'Dapatkan aplikasinya sekarang!',
      COPYRIGHT: '© 2024 Cinepoint. Seluruh hak cipta.'
    },
    SIGNED_OUT: {
      TITLE: 'Anda telah keluar!',
      DESC: 'Harap tunggu sementara Anda diarahkan ke halaman beranda.'
    },
    TOAST: {
      OTP_RESENT: {
        TITLE: 'Kode OTP dikirim ulang!',
        DESC: 'Silakan periksa kotak masuk ponsel Anda untuk mendapatkan kode OTP.'
      },
      OTP_REACHED_LIMIT: {
        TITLE: 'Telah mencapai Batas OTP!',
        DESC: 'Silahkan coba lagi 24 jam kemudian'
      },
      OTP_SEND_ERROR: {
        TITLE: 'Kesalahan pengiriman OTP!',
        DESC: 'Terjadi kesalahan tak terduga saat meminta kode OTP baru.'
      },
      ERROR_GET_SUBS: {
        TITLE: 'Tidak dapat mengambil langganan!',
        DESC: 'Silakan coba segarkan atau masuk lagi, atau hubungi dukungan pelanggan kami jika masalah terus berlanjut.',
      },
      ERROR_LOGIN: {
        TITLE: 'Tidak dapat masuk!',
        DESC: 'Terjadi kesalahan saat mencoba memasukkan Anda. Silakan coba lagi atau hubungi administrator kami.',
      },
      VERIF_LINK_RESENT: {
        TITLE: 'Tautan verifikasi dikirim ulang!',
        DESC: 'Silakan periksa email Anda (kotak masuk atau folder spam) dan klik tautan verifikasi untuk memverifikasi email Anda.',
      },
      VERIF_LINK_RESEND_ERROR: {
        TITLE: 'Permintaan pengiriman ulang gagal!',
        DESC: 'Sistem tidak mengenali alamat email yang Anda masukkan.'
      },
      SUBSCRIPTION_CANCELLED: {
        TITLE: 'Anda tidak lagi berlangganan Cinepoint.',
        DESC: 'Mulai berlangganan untuk mengakses akun Anda dan nikmati fitur kami sepenuhnya.'
      },
      SUBSCRIPTION_CANCEL_ERROR: {
        TITLE: 'Maaf, kami tidak dapat memproses permintaan pembatalan anda.',
        DESC: 'Server mungkin mengalami beberapa masalah atau langganan Anda telah dibatalkan. Jika masalah terus berlanjut, silakan hubungi administrator kami.'
      },
      SUBSCRIPTION_ENDED: {
        TITLE: 'Langganan Anda telah berakhir.',
        DESC: 'Silakan perbarui langganan Anda untuk terus menggunakan akun Anda.'
      },
      SUBSCRIPTION_NOTEXIST: {
        TITLE: 'Saat ini anda tidak berlangganan Cinepoint.',
        DESC: 'Silakan pilih paket berlangganan sebelum melanjutkan menggunakan akun anda.'
      },
      PERSONAL_INFO_ERROR: {
        TITLE: 'Gagal Menyimpan Info Pribadi!',
        DESC: 'Terjadi kesalahan tak terduga saat menyimpan informasi profil baru Anda. Silahkan periksa kembali data yang anda masukkan'
      },
      NO_CHANGE_PLAN: {
        TITLE: 'Plan lain akan segera tersedia',
      }
    },
    PERSONAL_INFORMATION: {
      MIN_AGE_ERROR: 'Anda harus berusia minimal 17 tahun.',
      DESC: 'Kelola informasi profil Anda untuk mengontrol, melindungi, dan mengamankan akun Anda'
    },
    PASSWORD_N_SECURITY: {
      DESC: 'Kelola informasi profil Anda untuk mengontrol, melindungi, dan mengamankan akun Anda'
    },
    DIALOG_MESSAGE: {
      SAVE_CHANGES: 'Apakah Anda yakin ingin menyimpan perubahan?'
    },
    CONFIRM_SAVE: {
      TITLE: 'Simpan perubahan',
      PROMPT: 'Apakah Anda yakin untuk menyimpan perubahan?',
    },
    SUBSCRIPTION_DETAIL: {
      TITLE: 'Detail Langganan',
      DESC: 'Detail langganan Anda saat ini'
    },
    SUBSCRIPTION_CANCEL:{
      TITLE: 'Batalkan Langganan',
      SUBTITLE: 'Kami sangat sedih melihat anda pergi, tolong beri tahu kami kenapa anda memutuskan untuk menghentikan langganan anda',
      PROMPT: 'Apakah Anda yakin ingin membatalkan langganan Anda? Setelah berhenti berlangganan, Anda akan kehilangan fitur berikut :',
      FEATS: {
        WRITE_REVIEW: 'Tidak dapat lagi menulis ulasan',
        COMPARE_MOVIES: 'Tidak lagi dapat membandingkan film'
      },
      REASON: {
        TECH_ISSUES: 'Masalah Teknis',
        TOO_EXPENSIVE: 'Terlalu mahal',
        SWITCH_PRODUCT: 'Beralih ke produk lain',
        NO_MORE_NEED: 'Tidak memerlukan layanan ini lagi',
        RARE_USE: 'Saya jarang menggunakan aplikasi ini',
        LAINNYA: 'Lainnya',
      }
    },
    SIGN_OUT: {
      PROMPT: 'Apakah Anda yakin ingin keluar dari akun Anda?'
    },
    CHANGE_PASSWORD: {
      DESC: 'Perbarui kredensial keamanan Anda secara berkala',
    },
    CHANGE_PHONE: {
      TOOLTIP: '<span>Anda dapat menghubungi admin melalui email <a class="text-primary-500 underline" href="mailto:help@cinepoint.com">help@cinepoint.com</a> untuk meminta perubahan nomor telepon .</span>'
    },
    CANCEL_CHANGE_EMAIL: {
      PROMPT: 'Apakah Anda yakin ingin membatalkan proses ini?'
    },
    MOVIE_DIRECTORY: {
      DISPLAYING_TOTAL_RESULTS: 'Menampilkan {{total}} hasil pencarian',
      COMPARE_WITH_OTHER_LINK: 'Bandingkan dengan film lain. Coba sekarang'
    },
    ADD_MOVIE_DIALOG: {
      PROMPT: 'Tambahkan film untuk memulai perbandingan film',
      SEARCH_PLACEHOLDER: 'Cari judul film atau nama pemeran',
      NO_RESULT: 'Tidak ada hasil. Silakan coba kata kunci lain.'
    },

    ABOUT_US: {
      BANNER_DESC: 'Pelajari lebih lanjut tentang perusahaan dan tim di baliknya.',
      PAGE_DESC: '',
      CONTENT: `
        Cinepoint is the most trusted recommendation resources for quality entertainment. As a leading online aggregator of Box Office Movies, we provide fans to give ratings for movies.  If you’re an entertainment fan looking for a recommendation, or to share ratings, you’ve come to the right place.
      `
    },

    FAQ: {
      BANNER_DESC: '',
      PAGE_DESC: ''
    },

    TERMS_CONDITION: {
      BANNER_DESC: '',
      PAGE_DESC: '',
      CONTENT: `
        Yo Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam.

        Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat.

        Odio felis sagittis, morbi feugiat tortor vitae feugiat fusce aliquet. Nam elementum urna nisi aliquet erat dolor enim. Ornare id morbi eget ipsum. Sapien, dictum molestie sem tempor.

        Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat.

        Odio felis sagittis, morbi feugiat tortor vitae feugiat fusce aliquet. Nam elementum urna nisi aliquet erat dolor enim. Ornare id morbi eget ipsum. Sapien, dictum molestie sem tempor.
      `
    },

    PRIVACY_POLICY: {
      BANNER_DESC: '',
      PAGE_DESC: '',
      CONTENT: `
        Yo Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam.

        Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat.

        Odio felis sagittis, morbi feugiat tortor vitae feugiat fusce aliquet. Nam elementum urna nisi aliquet erat dolor enim. Ornare id morbi eget ipsum. Sapien, dictum molestie sem tempor.

        Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat.

        Odio felis sagittis, morbi feugiat tortor vitae feugiat fusce aliquet. Nam elementum urna nisi aliquet erat dolor enim. Ornare id morbi eget ipsum. Sapien, dictum molestie sem tempor.
      `
    },

    CONTACT_US: {
      BANNER_DESC: 'Kami ingin mendengar pendapat Anda. Tim kami yang ramah selalu ada di sini untuk mengobrol.',
      PAGE_DESC: 'Kami ingin mendengar pendapat Anda. Tim kami yang ramah selalu ada di sini untuk mengobrol.',
      GET_IN_TOUCH: 'Hubungi Kami!',
      EMAIL_DESC: 'Tim kami yang ramah siap membantu.',
      ADDRESS_DESC: 'Silakan sapa kantor pusat kami.',
      PHONE_DESC: 'Senin-Jumat dari jam 8 pagi sampai jam 5 sore.'
    },

    DAILY_SHOWTIME: {
      CHART_TITLE: '“8 Teratas” Perbandingan Waktu Tayang',
    },
    PAGE_NOT_FOUND: {
      TITLE: 'Halaman tidak ditemukan',
      DESC: 'Maaf, halaman yang Anda cari tidak ada atau sudah dipindahkan.',
    },
    UNDER_MAINTENANCE: {
      TITLE: 'Situs Web Sedang Dalam Pemeliharaan',
      DESC: 'Maaf, website kami sedang dalam pemeliharaan, tapi kami hampir selesai. \nAnda dapat mencoba lagi suatu saat nanti.',
    },
    CHANGE_EMAIL: {
      SUBTITLE: 'Perbarui informasi kontak Anda',
    },
    CHANGE_PHONE_NUMBER: {
      SUBTITLE: 'Perbarui informasi kontak Anda',
    },
    SUBSCRIPTION: {
      SUBTITLE: 'Kelola benefit dan keanggotaan anda'
    },
    CHART: {
      NO_DATA: 'Tidak ada data untuk film ini.',
    },
    LOCKED_MESSAGE: {
      SUBSCRIBED_ONLY: 'Ini untuk pengguna berlangganan.',
      LOGIN_TO_SEE: 'Masuk ke akun anda untuk melihat grafik ini.',
    },
    CHANGE_EMAIL_SAVED: {
      TITLE: 'Permintaan Dikirim!',
      DESC: 'Email Anda telah diperbarui, tetapi Anda perlu memverifikasinya. Silakan periksa email Anda (kotak masuk atau folder spam) dan klik tautan verifikasi untuk memverifikasi email Anda. <b>Harap jangan tutup halaman ini jika Anda belum memverifikasi alamat email baru Anda.</b>'
    },
    CHANGE_PASSWORD_SAVED: {
      TITLE: 'Perubahan Disimpan!',
      DESC: 'Kata sandi Anda telah diperbarui. Silakan gunakan kata sandi baru Anda untuk masuk.'
    },
    PERSONAL_INFO_SAVED: {
      TITLE: 'Perubahan Disimpan!',
      DESC: 'Informasi pribadi Anda telah diperbarui.'
    },
    TOP_BOX_OFFICE: {
      DISCLAIMER: 'Data displayed are a combination of published data and Cinepoint’s proprietary tracking estimates.',
      TITLE: {
        DAILY: 'Film Terlaris Harian',
        WEEKLY: 'Film Terlaris Mingguan',
        MONTHLY: 'Film Terlaris Bulanan',
        YEARLY: 'Film Terlaris Tahunan',
      }
    },
    DEMOGRAPHIC: {
      DESC: 'Berdasarkan rating dari user',
    },
    BLOG: {
      MORE_LIKE_THIS: 'Artikel Lain',
      SUBHEADER: 'Pelajari berita terbaru tentang film dan aktor favorit Anda di sini.',
    },
    COMMON: {
      /* Other */
      CATEGORY: 'Kategori',
      SUBPERIOD: 'Subperiod',
      COMMENTS: 'Komentar',
      BLOG: 'Blog',
      BLOG_ARTICLES: 'Blog & Artikel',
      BY: 'Oleh',
      LOGIN_NOW: 'Masuk sekarang',
      ERROR_PAGE: 'Halaman Kesalahan',
      ERROR_404: '404 Error',
      PREVIOUS_DAY: 'Hari Sebelumnya',
      NEXT_DAY: 'Hari Berikutnya',
      PASSWORD_SECURITY: 'Kata Sandi & Keamanan',
      DETAIL: 'Detil',
      PAYMENT_HISTORY: 'Riwayat Pembayaran',
      PERSONAL_INFO: 'Info Personal',
      PASSWORD_N_SECURITY: 'Kata Sandi & Keamanan',
      MY_PLAN: 'Plan Saya',
      VIEW_DETAILS:'Lihat Detail',
      NO_RECORDS_FOUND: 'Data Tidak Ditemukan',

      CHAT_TO_US: 'Ngobrol dengan kami',
      OFFICE: 'Kantor',
      PHONE: 'Telefon',
      ABOUT_CINEPOINT: 'Tentang Cinepoint',
      ABOUT_US: 'Tentang Kami',
      FAQ_LONG: 'Pertanyaan yang Sering Diajukan',
      FAQ: 'Pertanyaan Umum',
      CONTACT_US: 'Hubungi Kami',
      ADD_MOVIE: 'Tambah film',
      MOVIE_COMPARISON: 'Perbandingan Film',
      SIMILAR_MOVIES: 'Film Serupa',
      PLAYING_AT: 'Sedang Tayang di',
      GO_TO_WEBSITE: 'Kunjungi situs',
      OTHERS: 'Lainnya',
      TOTAL_7_DAYS: '7-Hari Total',
      TOTAL_14_DAYS: '14-Hari Total',
      COMPARE_WITH_OTHER_MOVIES: 'Bandingkan dengan film lain',
      ADMISSION: 'Admisi',
      GROSS: 'Pendapatan Kotor',
      DEMOGRAPHIC: 'Demografi',
      THIS_WEEKS_ADMISSION: 'Penerimaan Minggu Ini',
      CAST_N_CREW: 'Pemeran & Kru',
      RELEASED: 'Dirilis',
      INFORMATION: 'Informasi',
      PRODUCTION_STATUS: 'Status Produksi',
      RUNNING_TIME: 'Waktu Berlangsung',
      COUNTRY: 'Negara',
      LANGUAGE: 'Bahasa',
      COMPARE: 'Bandingkan',
      PLAY_TRAILER: 'Putar Trailer',
      RELEASE_DATE: 'Tanggal Rilis',
      RESET_FILTER: 'Setel Ulang Filter',
      POPULAR_MOVIES: 'Film Populer',
      GENRE: 'Genre',
      RATING: 'Peringkat',
      SORT: 'Urutkan',
      ALL: 'Semua',
      OTHER: 'Lainnya',
      CHANGE_EMAIL: 'Ubah Email',
      CHANGE_PHONE_NUMBER: 'Ubah Nomor Telefon',
      MOVIE_DIRECTORY: 'Direktori Film',
      CANCEL_CHANGE_EMAIL: 'Batal Ubah Email',
      CANCEL_CHANGE_PASSWORD: 'Batal Ubah Kata Sandi',
      NO: 'Tidak',
      YES: 'Ya',
      VERIFIED: 'Terverifikasi',
      UNVERIFIED: 'Belum Diverifikasi',
      CHANGE_PASSWORD: 'Ubah Kata Sandi',
      CONFIRM_NEW_PASSWORD: 'Konfirmasi Kata Sandi Baru',
      NEW_PASSWORD: 'Kata Sandi Baru',
      SAVE_PASSWORD: 'Simpan Kata Sandi',
      SELECT_REASON: 'Pilih alasan',
      EMAIL_SENT_START: 'Sebuah email sudah dikirim ke ',
      EMAIL_SENT_END: ' dengan tautan untuk memverifikasi akun Anda.',
      EMAIL_SENT_VERIFY: 'Harap verifikasi akun Anda terlebih dahulu sebelum masuk.',
      PLACEHOLDER_PASSWORD: 'Masukkan kata sandi',
      MY_PROFILE: 'Profil Saya',
      LOGOUT: 'Keluar',
      ACCOUNT_SETUP: 'Penyiapan Akun',
      RESEND_CODE: 'Kirim Ulang',
      BACK: 'Kembali',
      SUBMIT: 'Kirim',
      OR_PHONE_NO: 'Atau Nomor Telepon',
      PLACEHOLDER_EMAIL: 'Masukkan alamat email Anda',
      PLACEHOLDER_PHONE: 'Masukkan nomor telepon Anda',
      NEED_HELP: 'Butuh bantuan?',
      HELP_CENTER: 'Pusat Bantuan',
      TERMS_CONDITION: 'Syarat & Ketentuan',
      PRIVACY_POLICY: 'Kebijakan Privasi',
      BROWSE_MOVIE: 'Telusuri film',
      SUMMARY: 'Ringkasan',
      SUBTOTAL: 'Subtotal',
      TAX: 'Pajak',
      DISCOUNT: 'Diskon',
      TOTAL: 'Total',
      PAY_NOW: 'Bayar Sekarang',
      SUBSCRIPTION: 'Langganan',
      CHANGE_PLAN: 'Ubah Plan',
      APPLY: 'Terapkan',
      MONTH: 'Bulan',
      YEAR: 'Tahun',
      POPULAR: 'Populer',
      MONTHLY_PLAN: 'Plan Bulanan',
      YEARLY_PLAN: 'Plan Tahunan',
      RECOMMENDED: 'Rekomendasi',
      NEXT: 'Berikutnya',
      RESEND_VERIFICATION_LINK: 'Kirim ulang tautan verifikasi',
      CONTINUE: 'Lanjutkan',
      FIRST_NAME: 'Nama Depan',
      LAST_NAME: 'Nama Belakang',
      EMAIL: 'Email',
      PHONE_NO: 'Nomor Telepon',
      PASSWORD: 'Kata Sandi',
      CONFIRM_PASSWORD: 'Konfirmasi Kata Sandi',
      SIGN_IN: 'Masuk',
      SIGN_UP: 'Daftar',
      SIGN_OUT: 'Keluar',
      SELECT_PLAN: 'Pilih Plan',
      PAYMENT: 'Pembayaran',
      ALL_COUNTRIES: 'Semua Negara',
      LOCAL: 'Lokal',
      INTERNATIONAL: 'Internasional',
      PERIOD: 'Periode',
      ROWS_PER_PAGE: 'Baris per hal',
      RANK: 'Peringkat',
      TITLE: 'Judul',
      WEEKLY_ADM: 'Adm. Mingguan',
      CHANGE: 'Perubahan',
      TOTAL_ADM: 'Total Admisi',
      SHOWTIMES: 'Waktu Tayang',
      SCORE: 'Skor',
      WEEKLY: 'Mingguan',
      WEEKLY_BY_RELEASE_DATE: 'Mingguan - Tanggal Rilis',
      DAILY: 'Harian',
      DAILY_ADM: 'Adm. Harian',
      MONTHLY_ADM: 'Adm. Bulanan',
      YEARLY_ADM: 'Adm. Tahunan',
      MONTHLY: 'Bulanan',
      YEARLY: 'Tahunan',
      TOP_BOX_OFFICE: 'Papan Atas Box Office',
      SEPTEMBER: 'September',
      OCTOBER: 'Oktober',
      NOVEMBER: 'November',
      UPCOMING_MOVIES: 'Film Akan Datang',
      SEE_ALL: 'Lihat selengkapnya',
      LATEST_MOVIES: 'Film Terbaru',
      HOME: 'Beranda',
      BACK_TO_HOME: 'Kembali ke Halaman Utama',
      DAILY_SHOWTIME: 'Jadwal Harian',
      MOVIE_DIR: 'Direktori Film',
      MOVIE_COMP: 'Perbandingan Film',
      MOVIES: 'Film',
      CASTS: 'Pemeran',
      CAST: 'Pemeran',
      PRODUCER: 'Produser',
      WRITER: 'Penulis',
      DIRECTOR: 'Sutradara',
      CHANGE_IMAGE: 'Ganti Gambar',
      ENTER: 'Masukkan',
      ENTER_YOUR: 'Masukkan',
      DATE_OF_BIRTH: 'Tanggal Lahir',
      GENDER: 'Jenis Kelamin',
      MALE: 'Laki-laki',
      FEMALE: 'Perempuan',
      SAVE_CHANGES: 'Simpan Perubahan',
      CANCEL_SUBSCRIPTION: 'Batal Berlangganan',
      STAY_SUBSCRIBED: 'Tetap Berlangganan',
      CANCELATION_REASON: 'Alasan Pembatalan',
      FEEDBACK: 'Umpan Balik',
      NOTE: 'Catatan',
      CANCEL_MY_SUBSCRIPTION: 'Batalkan Langganan Saya',
      DATE: 'Tanggal',
      DESCRIPTION: 'Deskripsi',
      PAYMENT_METHOD: 'Metode Pembayaran',
      CURRENT_PASSWORD: 'Password Saat Ini',
      CURRENT_PLAN: 'Plan Saat Ini',
      CANCEL: 'Batal',
      PERSONAL_INFORMATION: 'Informasi Personal',
      MOVIE_POSTER_UNAVAIL: 'Poster Film\nTidak Tersedia',
      IMAGE_UNAVAIL: 'Gambar\Tidak Tersedia',
      UPLOAD_IMAGE: 'Unggah Gambar (JPG/PNG)',
      MAX_SIZE_5MB: 'Ukuran maksimal 5 MB',
      UPLOAD_IMAGE_SUCCESSFULL: 'Berhasil unggah gambar !',
      IMAGE_SIZE_TOO_BIG: 'Ukuran gambar terlalu besar !',
      INVALID_FILE_FORMAT: 'Format file tidak sesuai !',
      ERROR: 'Terjadi kesalahan',
      SAVE: 'Simpan',
      DATA_CHANGES: 'Data Berubah',
      BILLED_MONTHLY: 'Ditagih bulanan',
      BILLED_YEARLY: 'Ditagih tahunan',
      NEXT_BILLING_DATE: 'Tanggal Tagihan Berikutnya',
      PASSWORD_AND_SECURITY: 'Kata Sandi & Keamanan',
      TAKE_ME_HOME: 'Pergi ke Halaman Utama',
      SEE_ALL_CAST_N_CREW: 'Lihat semua pemeran & kru',
      VOUCHER_CODE:'Kode Voucher',

      /* Movie Genre */
      SPORT: 'Olahraga',
      ACTION: 'Aksi',
      HORROR: 'Horor',
      DRAMA: 'Drama',
      THRILLER: 'Thriller',
      'SCI-FI': 'Fiksi Ilmiah',
      ROMANCE: 'Percintaan',
      COMEDY: 'Komedi',
      CRIME: 'Kejahatan',
      WESTERN: 'Barat',
      ANIMATION: 'Animasi',
      DOCUMENTARY: 'Dokumenter',
      FANTASY: 'Fantasi',
      ADVENTURE: 'Petualangan',
      MUSICAL: 'Musikal',
      HISTORICAL: 'Sejarah',
      NARRATIVE: 'Naratif',
      NOIR: 'Noir',
      EXPERIMENTAL: 'Eksperimental',
      ROMANTIC_COMEDY: 'Komedi Romantis',
      MUSIC: 'Musik',
      MYSTERY: 'Misteri',
      TELEVISION: 'Televisi',
      HISTORICAL_FICTION: 'Fiksi Sejarah',
      FICTION: 'Fiksi',
      SPAGHETTI_WESTERN: 'Spaghetti Western',
      ART: 'Seni',
      DISASTER: 'Bencana',
      WAR: 'Perang',
      SHORT: 'Pendek',
      EXPLOITATION: 'Eksploitasi',
      SATIRE: 'Satir',
      SLASHER: 'Slasher',
      BIOGRAPHY: 'Biografi',
      SORT_N_FILTER_MOVIE: 'Urut & Saring Film',
      FILTER: 'Saring',
      UNTIL: 'Hingga',
      AUTOMATICALLY_EXTENDED: 'Diperpanjang Otomatis',

      /* Movie Rating */
      G: 'G',
      PG: 'PG',
      'PG-13': 'PG-13',
      R: 'R',
      'NC-17': 'NC-17',
      NOT_RATED: 'Not Rated',
      UNRATED: 'Unrated',
    },
  },
};
